export interface DischargeOptionOptions {
  label: string;
  value: string;
  groupTypeId: null | string;
  displayName: string;
}

function getDefaults(): DischargeOptionOptions {
  return {
    label: '',
    value: '',
    groupTypeId: null,
    displayName: '',
  };
}

export default class DischargeOption {
  label: string;
  value: string;
  groupTypeId: null | string;
  displayName: string;

  constructor(options: Partial<DischargeOptionOptions> = {}) {
    const opts = { ...getDefaults(), ...options };

    this.label = opts.label;
    this.value = opts.value;
    this.groupTypeId = opts.groupTypeId;
    this.displayName = opts.displayName;
  }

  get canContinueCare() {
    const nonContinuedCareReasons = ['assisted living', 'long term care', 'skilled_nursing_facility'];

    return Boolean(this.groupTypeId) && !nonContinuedCareReasons.includes(this.value);
  }
}
