import { parseDate } from 'lib/date';
import { type ProgressUpdateOptions } from 'models/activities/ProgressUpdate';
import AttrValue, { AttrValueOptions } from 'models/AttrValue';
import { AssignedUserRole, LocationEpisodeUser } from 'models/LocationEpisodeUser';
import AltcsApplication, { AltcsApplicationOptions } from 'models/reviews/AltcsApplication';
import Authorization, { AuthorizationOptions } from 'models/reviews/Authorization';
import ProjectedDischarge, { ProjectedDischargeOptions } from 'models/reviews/ProjectedDischarge';
import ReviewModelFactory from 'models/reviews/ReviewModelFactory';
import { ServiceRefusalOptions } from 'models/reviews/ServiceRefusal';

import Classification, { ClassificationOptions } from './Classification';
import Group, { GroupOptions } from './Group';
import Patient, { PatientOptions } from './Patient';
import QuestionTemplate, { QuestionTemplateOptions, QuestionTemplateType } from './QuestionTemplate';
import RehabState, { RehabStateOptions } from './RehabState';
import Review from './Review';

type RehabInformationOptions = {
  latestRehabFacilityType: string;
  eligibleForProgressUpdate: boolean;
  latestRehabFacility: Partial<GroupOptions>;
};

export interface LocationEpisodeOptions {
  id: string;
  archived: boolean;
  currentRehabState: Partial<RehabStateOptions>;
  admittedOn: string | Date;
  endDate: string | Date;
  episodeId: string;
  episodeClassification: Partial<ClassificationOptions> | null;
  hasNewChanges: boolean | null;
  hospital: Partial<GroupOptions>;
  lastValidPredecessor: Partial<LocationEpisodeOptions> | null;
  latest: boolean;
  lengthOfStay: number;
  groupId: string;
  owner: Partial<GroupOptions>;
  patient: Partial<PatientOptions>;
  planTypeClassification: Partial<ClassificationOptions> | null;
  physicianTeam: Partial<AttrValueOptions>;
  questionTemplates: Partial<QuestionTemplateOptions>[];
  rehabInformation: Partial<RehabInformationOptions>;
  rehabStates: Partial<RehabStateOptions>[];
  reviews: (
    | Partial<AltcsApplicationOptions>
    | Partial<AuthorizationOptions>
    | Partial<ProjectedDischargeOptions>
    | Partial<ServiceRefusalOptions>
  )[];
  startDate: string;
  onTrack: string | null;
  onTrackUpdatedAt: string | null;
  statusOutOfDate: boolean;
  assignedUsers: Partial<LocationEpisodeUser>[] | null;
  hasActiveServiceRefusals: boolean;
  latestProgressUpdate: Partial<ProgressUpdateOptions> | null;
}

function getDefaults(): LocationEpisodeOptions {
  return {
    id: '',
    archived: false,
    currentRehabState: new RehabState(),
    admittedOn: '',
    endDate: '',
    episodeId: '',
    hasNewChanges: false,
    hospital: {
      name: '',
    },
    lastValidPredecessor: {},
    latest: true,
    lengthOfStay: 0,
    groupId: '',
    owner: {},
    patient: {},
    episodeClassification: null,
    planTypeClassification: null,
    physicianTeam: {
      name: '',
    },
    questionTemplates: [],
    rehabInformation: {
      eligibleForProgressUpdate: false,
      latestRehabFacility: {},
    },
    rehabStates: [new RehabState()],
    reviews: [],
    startDate: '',
    onTrack: null,
    onTrackUpdatedAt: null,
    statusOutOfDate: false,
    assignedUsers: null,
    hasActiveServiceRefusals: false,
    latestProgressUpdate: null,
  };
}
/**
 * LocationEpisode
 * @class LocationEpisode
 * @param {Partial<LocationEpisodeOptions>} [options={}]
 * @property {string} id - The id of the location episode
 * @property {boolean} archived - Whether the location episode is archived
 * @property {RehabState} currentRehabState - The current rehab state of the location episode
 * @property {Date} admittedOn - The date the patient was admitted
 * @property {Date} endDate - The date the location episode ended
 * @property {string} episodeId - The id of the episode
 * @property {Classification | undefined} episodeClassification - The classification of the episode
 * @property {boolean} hasNewChanges - Whether the location episode has new changes
 * @property {Group | null} hospital - The hospital of the location episode
 * @property {LocationEpisode | null} lastValidPredecessor - The last valid predecessor of the location episode
 * @property {boolean} latest - Whether the location episode is the latest
 * @property {number} lengthOfStay - The length of stay of the location episode
 * @property {string} groupId - The id of the group
 * @property {Group} owner - The owner of the location episode
 * @property {Patient} patient - The patient of the location episode
 * @property {Classification | undefined} planTypeClassification - The plan type classification of the location episode
 * @property {AttrValue} physicianTeam - The physician team of the location episode
 * @property {QuestionTemplate[]} questionTemplates - The question templates of the location episode
 * @property rehabInformation - The rehab information of the location episode
 * @property {RehabState[]} rehabStates - The rehab states of the location episode
 * @property {Review[]} reviews - The reviews of the location episode
 * @property {string} startDate - The start date of the location episode
 * @property {string | null} onTrack - The on track of the location episode
 * @property {string | null} onTrackUpdatedAt - The on track updated at of the location episode
 * @property {boolean} statusOutOfDate - Whether the status of the location episode is out of date
 * @property {User | null} caseManager - The case manager of the location episode
 * @property {boolean} hasActiveServiceRefusals - Whether the location episode has active service refusals
 * @method stayDescriptor - The stay descriptor of the location episode
 * @method activeAuthorizationReview - The active authorization review of the location episode
 * @method projectedDischargeReview - The projected discharge review of the location episode
 * @method discharged - The discharged of the location episode
 * @method dischargedBeforeProjectedDate - The discharged before projected date of the location episode
 * @method dischargeOverdue - The discharge overdue of the location episode
 * @method progressTemplate - The progress template of the location episode
 * @method dischargeTemplate - The discharge template of the location episode
 * @method inAdmission - The in admission of the location episode
 * @method inTreatment - The in treatment of the location episode
 * @example const locationEpisode = new LocationEpisode({ id: '38ce0cea-f787-11ea-8e07-07fe8aa91684' });
 * @returns {LocationEpisode}
 */
export default class LocationEpisode {
  id: string;
  archived: boolean;
  currentRehabState: RehabState;
  admittedOn: Date;
  endDate: Date;
  episodeId: string;
  episodeClassification: Classification | null;
  hasNewChanges: boolean | null;
  hospital: Group;
  lastValidPredecessor: Partial<LocationEpisodeOptions> | null;
  latest: boolean;
  lengthOfStay: number;
  groupId: string;
  owner: Group;
  patient: Patient;
  planTypeClassification: Classification | null;
  physicianTeam: AttrValue;
  questionTemplates: QuestionTemplate[];
  rehabInformation: {
    latestRehabFacilityType?: string;
    eligibleForProgressUpdate?: boolean;
    latestRehabFacility: Group;
  };
  rehabStates: RehabState[];
  reviews: Review[];
  startDate: string;
  onTrack: string | null;
  onTrackUpdatedAt: string | null;
  statusOutOfDate: boolean;
  assignedUsers: LocationEpisodeUser[] | null;
  hasActiveServiceRefusals: boolean;
  latestProgressUpdate: Partial<ProgressUpdateOptions> | null;

  constructor(options: Partial<LocationEpisodeOptions> = {}) {
    const opts = { ...getDefaults(), ...options };

    this.id = opts.id;
    this.archived = opts.archived;
    this.currentRehabState = new RehabState(opts.currentRehabState);
    this.endDate = parseDate(opts.endDate) as Date;
    this.episodeId = opts.episodeId;
    this.episodeClassification = opts.episodeClassification ? new Classification(opts.episodeClassification) : null;
    this.hasNewChanges = opts.hasNewChanges;
    this.hospital = new Group(opts.hospital);
    this.lastValidPredecessor = opts.lastValidPredecessor;
    this.latest = opts.latest;
    this.lengthOfStay = opts.lengthOfStay;
    this.groupId = opts.groupId;
    this.owner = new Group(opts.owner);
    this.patient = new Patient(opts.patient);
    this.planTypeClassification = opts.planTypeClassification ? new Classification(opts.planTypeClassification) : null;
    this.physicianTeam = new AttrValue(opts.physicianTeam);
    this.questionTemplates = opts.questionTemplates.map((x) => new QuestionTemplate(x));
    this.rehabInformation = {
      ...opts.rehabInformation,
      latestRehabFacility: new Group(opts.rehabInformation?.latestRehabFacility),
    };
    this.rehabStates = opts.rehabStates.map((x) => new RehabState(x));
    this.reviews = opts.reviews.map((review) => ReviewModelFactory.get(review));

    this.startDate = opts.startDate;
    this.onTrack = opts.onTrack;
    this.onTrackUpdatedAt = opts.onTrackUpdatedAt;
    this.statusOutOfDate = opts.statusOutOfDate;
    this.assignedUsers = opts.assignedUsers?.map((x) => new LocationEpisodeUser(x)) || null;
    this.hasActiveServiceRefusals = opts.hasActiveServiceRefusals;
    this.admittedOn = parseDate(opts.admittedOn) as Date;
    this.latestProgressUpdate = opts.latestProgressUpdate || null;
  }

  get caseManagerUser() {
    return this.assignedUsers?.find((x) => x.userRole === AssignedUserRole.CaseManager) || null;
  }

  get utilizationManagerUser() {
    return this.assignedUsers?.find((x) => x.userRole === AssignedUserRole.UtilizationManager) || null;
  }

  get stayDescriptor() {
    if (this.archived) return 'Canceled';
    return this.latest ? 'Current' : 'Past';
  }

  get activeAuthorizationReview() {
    return this.reviews.find((r): r is Authorization => (r.pending || r.accepted) && r instanceof Authorization);
  }

  get projectedDischargeReview() {
    return this.reviews.find((r): r is ProjectedDischarge => r instanceof ProjectedDischarge);
  }

  get altcsApplicationReview() {
    return this.reviews.find((r): r is AltcsApplication => r instanceof AltcsApplication);
  }

  get discharged() {
    return this.currentRehabState.discharged;
  }

  get dischargedBeforeProjectedDate() {
    if (!this.discharged) return false;
    if (!this.projectedDischargeReview?.date) return false;
    const enteredAt = this.currentRehabState.enteredAt;
    return enteredAt && enteredAt < this.projectedDischargeReview?.date;
  }

  get dischargeOverdue() {
    if (!this.projectedDischargeReview?.date) return false;
    if (this.dischargedBeforeProjectedDate) return false;

    return new Date() > this.projectedDischargeReview.date;
  }

  get progressTemplate() {
    return (
      this.questionTemplates.find((template) => template.isProgress) ||
      new QuestionTemplate({ type: QuestionTemplateType.PROGRESS })
    );
  }

  get dischargeTemplate() {
    return this.questionTemplates.find((template) => template.isDischarge) || null;
  }

  get inAdmission() {
    return this.currentRehabState.admission;
  }

  get inTreatment() {
    return this.currentRehabState.inTreatment;
  }

  get inQueue() {
    return this.currentRehabState.queue;
  }
}
