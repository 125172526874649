export enum RouteConstants {
  INSIGHTS_BASE = '/insights',
  OLIO_ENGAGEMENT_SCORE = 'olio-engagement-score',
  AVERAGE_LENGTH_OF_STAY = 'average-length-of-stay',
  READMISSION_RATE = 'readmission-rate',
  TOTAL_DISCHARGES = 'total-discharges',
  PRIORITIES_NOTE_RESPONSE = 'priorities',
  ESCALATIONS_RESPONSE = 'escalations',
  ACTIVITIES_PER_PATIENT_PER_WEEK = 'activities-per-patient-per-week',
}

export enum MetricConstants {
  ACTIVITIES_PER_PATIENT_PER_WEEK = 'activities_per_patient_per_week',
  AVERAGE_LENGTH_OF_STAY = 'length_of_stay:average',
  BUSINESS_RESPONSE_TIME = 'business_response_time:average',
  ID_COUNT = 'id:count',
  LENGTH_OF_STAY_SUM = 'length_of_stay:sum',
  LOCATION_EPISODE_ID_COUNT = 'location_episode_id:count',
  OLIO_ENGAGEMENT_SCORE = 'olio_engagement_score',
  OWNER_ACTIVITIES_COUNT = 'owner_activities:count',
  OWNER_ACTIVITIES_SUM = 'owner_activities:sum',
  READMISSION_RATE = 'readmission_rate',
  READMISSIONS_COUNT = 'readmissions:count',
  RESPONSE_TIME = 'response_time:average',
}

export enum DimensionConstants {
  DATE = 'date',
  EPISODE_URL = 'episode_url',
  GROUP_NAME = 'group_name',
  LENGTH_OF_STAY = 'length_of_stay',
  OWNER_GROUP_NAME = 'owner_group_name',
  PATIENT_NAME = 'patient_name',
  PROVIDER_NAME = 'provider_name',
  PLAN_TYPE = 'plan_type',
  EPISODE_TYPE = 'episode_type',
  OWNER_CLIENT = 'owner_client',
  PROVIDER_CLIENT = 'provider_client',
  READMISSION_DATE = 'readmission_date',
}

export enum SourceConstants {
  LOCATION_EPISODE_DAYS = 'location_episode_days',
  LOCATION_EPISODE_DISCHARGES = 'location_episode_discharges',
  LOCATION_EPISODES = 'location_episodes',
  ESCALATION_ACKS = 'escalation_acks',
}

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}
