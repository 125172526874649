import Review, {
  BaseReviewData,
  BaseReviewDataOptions,
  getDefaults as getReviewDefaults,
  ReviewOptions,
  ReviewType,
} from 'models/Review';

type AltcsApplicationData = {
  ltcGroupId: string;
  dischargeReason: string;
};

export interface AltcsApplicationOptions extends ReviewOptions {
  type: ReviewType.ALTCS_APPLICATION;
  data: Partial<AltcsApplicationData & BaseReviewDataOptions>;
}

export function getDefaults(): AltcsApplicationOptions {
  const defaults = getReviewDefaults();
  return {
    ...defaults,
    type: ReviewType.ALTCS_APPLICATION,
    data: {
      ...defaults.data,
      ltcGroupId: '',
      dischargeReason: '',
    },
  };
}

export default class AltcsApplication extends Review {
  declare type: ReviewType.ALTCS_APPLICATION;
  declare data: AltcsApplicationData & BaseReviewData;

  constructor(options: Partial<AltcsApplicationOptions> = {}) {
    const opts = { ...getDefaults(), ...options };

    super(opts);
  }

  static get type() {
    return ReviewType.ALTCS_APPLICATION;
  }
}
