import React, { useCallback, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

import { ActivityInputValues, defaultActivityInputValues } from 'components/shared/activityInput/useActivityInput';
import AttachmentComponent from 'components/shared/Attachment';
import Button from 'components/shared/Button';
import FileDropZone from 'components/shared/FileDropZone';
import Checkbox from 'components/shared/form/Checkbox';
import useUpload from 'hooks/useDirectUpload/useDirectUpload';
import { EscalationTypes } from 'models/Escalation';
import Profile from 'models/Profile';
import { useToastActions } from 'stores/toastStore';
import { colors } from 'styles/theme/colors';
import AtIcon from 'svg/AtIcon';
import AttachFileIcon from 'svg/AttachFileIcon';

import { TaggableTextArea } from './TaggableTextArea';

type TextAreaCustomStyles = {
  minHeight?: string;
  maxHeight?: string;
};

type ActivityInputProps = {
  showPostButton?: boolean;
  showEscalate?: boolean;
  taggable?: boolean;
  attachable?: boolean;
  submitting?: boolean;
  escalationType?: EscalationTypes;

  locationEpisodeId?: string;
  heightSettings?: TextAreaCustomStyles;
  placeholder?: string;
  profile?: Profile | null; //needed if you aren't overriding note creation
  darkBorder?: boolean;

  values?: ActivityInputValues;
  setValues: React.Dispatch<React.SetStateAction<ActivityInputValues>>;
  onSubmit?: () => void;
  onUploading?: (uploading: boolean) => void;
};

export default function ActivityInput(props: ActivityInputProps) {
  const {
    attachable = true,
    taggable = true,
    submitting = false,
    darkBorder = true,
    placeholder,
    locationEpisodeId,
    showPostButton = false,
    showEscalate = false,
    heightSettings,
    onSubmit,
    values = defaultActivityInputValues,
    setValues,
    escalationType,
    onUploading,
  } = props;

  const { addToast } = useToastActions();
  const taggableTextAreaRef = useRef<any>(null);
  const { uploadFiles, uploads, uploading, removeUpload, resetUploads, setDocType } = useUpload(values.attachments);

  useEffect(() => {
    setValues((prev) => ({ ...prev, attachments: uploads }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploads]);

  useEffect(() => {
    onUploading?.(uploading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploading]);

  const handleTagClick = useCallback(() => {
    taggableTextAreaRef.current?.focus();

    setValues((prev) => {
      const tagStart = prev.text.match(/\s$/) ? '@' : ' @';
      return { ...prev, text: prev.text + tagStart };
    });
  }, [setValues]);

  const handleSubmit = useCallback(async () => {
    if (uploads.some((upload) => upload.hasError)) {
      addToast({ text: 'Please remove and re-upload the file(s) that failed' });
      return;
    }

    await onSubmit?.();
    resetUploads();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSubmit, resetUploads, uploads]);

  const overlayText = useCallback((valid: boolean) => (valid ? 'Drag your files here to attach' : 'Invalid files'), []);
  const disablePost = uploading || !showPostButton || !locationEpisodeId || !(values.text || values.attachments.length);

  return (
    <FileDropZone onFilesSelected={uploadFiles} currentFileCount={values.attachments.length} noDrag={false} noClick>
      {(dropzone) => (
        <Container
          $darkBorder={darkBorder}
          {...dropzone.getRootProps({
            $isDragAccept: dropzone.isDragAccept,
            $isDragActive: dropzone.isDragActive,
            $isDragReject: dropzone.isDragReject,
          })}>
          <input {...dropzone.getInputProps()} />
          <Overlay $isDragActive={dropzone.isDragActive}>
            <OverlayTitle>{overlayText(dropzone.isDragAccept)}</OverlayTitle>
          </Overlay>
          <TextAreaWrapper $textarea={heightSettings}>
            <TaggableTextArea
              values={values}
              onChange={(values) => setValues((prev) => ({ ...prev, ...values }))}
              placeholder={placeholder}
              disabled={submitting}
              inputRef={taggableTextAreaRef}
              locationEpisodeId={locationEpisodeId}
              taggable={taggable}
            />
            <FilesContainer>
              {values.attachments.map((upload) => (
                <AttachmentComponent
                  disabled={false}
                  error={upload.hasError}
                  key={upload.id}
                  uploadProgress={upload.progress}
                  attachment={upload}
                  onDeleteAttachment={removeUpload}
                  onDocumentTypeChange={setDocType}
                />
              ))}
            </FilesContainer>
          </TextAreaWrapper>
          <ActionsBar $darkBorder={darkBorder}>
            {taggable && (
              <IconButton size='xsmall' variant='ghost' onClick={handleTagClick} type='button'>
                <AtIcon />
                <span>Tag</span>
              </IconButton>
            )}

            {attachable && (
              <IconButton
                size='xsmall'
                variant='ghost'
                disabled={values.attachments.length >= 10}
                onClick={dropzone.open}
                type='button'>
                <AttachFileIcon />
                <span>Attach</span>
              </IconButton>
            )}

            {showEscalate && (
              <EscalateActions $darkBorder={false}>
                <Checkbox
                  checked={values.escalated || false}
                  checkedColor={colors.accentRed}
                  size={14}
                  labelSize='12px'
                  label={escalationType === EscalationTypes.PriorityNote ? 'Mark as priority' : 'Escalate'}
                  onChange={() => setValues((prev) => ({ ...prev, escalated: !prev.escalated }))}
                />
              </EscalateActions>
            )}
            {showPostButton && (
              <SubmitButton disabled={disablePost} onClick={handleSubmit} loading={submitting}>
                Post
              </SubmitButton>
            )}
          </ActionsBar>
        </Container>
      )}
    </FileDropZone>
  );
}

const TextAreaWrapper = styled.div<{ $textarea?: TextAreaCustomStyles }>`
  padding: 16px;
  box-sizing: border-box;
  min-height: ${({ $textarea }) => $textarea?.minHeight || '120px'};
  ${({ $textarea }) => {
    if ($textarea?.maxHeight) {
      return css`
        max-height: ${$textarea.maxHeight};
        overflow-y: scroll;
      `;
    }
    return null;
  }};
  display: flex;
  flex-direction: column;
`;

const Container = styled.div<{ $darkBorder: boolean }>`
  background-color: white;
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ $darkBorder }) => ($darkBorder ? 'var(--black-25)' : 'var(--black-10)')};
  position: relative;
  display: flex;
  flex-direction: column;

  &:focus-within {
    border-color: var(--primary-blue);
  }

  &:focus-visible {
    outline: none;
  }
`;

const ActionsBar = styled.div<{ $darkBorder: boolean }>`
  display: flex;
  align-items: center;
  border-top: 1px solid ${({ $darkBorder }) => ($darkBorder ? 'var(--black-25)' : 'var(--black-10)')};
  padding: 8px 16px;
  gap: 16px;
`;

const EscalateActions = styled.div<{ $darkBorder: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  border-left: 1px solid ${({ $darkBorder }) => ($darkBorder ? 'var(--black-25)' : 'var(--black-10)')};
  padding: 4px 0px 4px 16px;
  gap: 10px;
`;

const Overlay = styled.div<{ $isDragActive: boolean }>`
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${() => colors.black50};
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  display: ${({ $isDragActive }) => ($isDragActive ? 'flex' : 'none')};
`;

const OverlayTitle = styled.h3`
  color: ${() => colors.white};
`;

const SubmitButton = styled(Button)`
  padding: 3px 18px;
  line-height: 1.5;
  border: 2px solid transparent;
  font-size: 10px;
  line-height: 14px;
  margin-left: auto;
`;

const FilesContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  margin-top: 1rem;
  gap: 0.5rem;
`;

const IconButton = styled(Button)`
  padding: 0;
  color: var(--black);
  font-weight: normal;

  span {
    display: none;

    @media ${({ theme }) => theme.devices.desktop} {
      display: inline;
    }
  }
`;
