import { useMemo, useState } from 'react';
import styled from 'styled-components';

import { DimensionConstants, MetricConstants, RouteConstants, SourceConstants } from 'components/insights/constants';
import { DataRow } from 'components/insights/layout/helpers/dataUtils';
import useDataFetch from 'components/insights/layout/helpers/useDataFetch';
import MetricTile from 'components/insights/layout/tiles/MetricTile';
import MetricValue from 'components/shared/charts/MetricValue';
import NoteIconOutline from 'svg/NoteIconOutline';
import PatientsIcon from 'svg/PatientsIcon';

export default function ActivitiesPerPatientPerWeekTile() {
  const [activities, setActivities] = useState(-1);
  const [patients, setPatients] = useState(-1);
  const [activitiesPerPatientPerWeek, setActivitiesPerPatientPerWeek] = useState(-1);

  const request = useMemo(
    () => ({
      params: {
        source: SourceConstants.LOCATION_EPISODE_DAYS,
        dimensions: [DimensionConstants.GROUP_NAME],
        metrics: [
          MetricConstants.ACTIVITIES_PER_PATIENT_PER_WEEK,
          MetricConstants.LOCATION_EPISODE_ID_COUNT,
          MetricConstants.OWNER_ACTIVITIES_SUM,
        ],
        rollups: true,
      },
      processData: (data: DataRow[]) => {
        data.find((row) => {
          if (row.grouping === 1) {
            setActivitiesPerPatientPerWeek(Number(row.metricValues[0].value));
            setPatients(Number(row.metricValues[1].value));
            setActivities(Number(row.metricValues[2].value));
          }
        });
      },
    }),
    []
  );

  const { loading, customRef } = useDataFetch([request], { onIntersecting: true });

  return (
    <MetricTile
      ref={customRef}
      showValue={true}
      loading={loading}
      data-gtm-id='activitiesPerPatientPerWeek'
      data-cy='activitiesPerPatientPerWeek'
      label='Activities Per Patient Per Week'
      value={`${activitiesPerPatientPerWeek.toFixed(1)}`}
      hasData={patients > 0}
      navigateTo={RouteConstants.ACTIVITIES_PER_PATIENT_PER_WEEK}>
      <MetricWrapper>
        <MetricValue icon={NoteIconOutline} label='Activities' value={activities} />
        <MetricValue icon={PatientsIcon} label='Patients' value={patients} />
      </MetricWrapper>
    </MetricTile>
  );
}

export const MetricWrapper = styled.div`
  display: flex;
  gap: 8px;
  height: 100%;
  width: 100%;
`;
