import { GenericAbortSignal } from 'axios';

import DischargeOption, { DischargeOptionOptions } from 'models/DischargeOption';
import { BaseIndexQueryParams } from 'services/api/types';
import http from 'services/http';

export const dischargeOptionsQueryKeys = {
  index: (episodeId: string) => ['dischargeOptions', episodeId],
};

type IndexParams = BaseIndexQueryParams & {
  episodeId: string;
};
export async function indexDischargeOptions(params: IndexParams, signal: GenericAbortSignal) {
  return http
    .get<DischargeOptionOptions[]>(`discharge_options`, { params, signal })
    .then((response) => response.data.map((option) => new DischargeOption(option)));
}
