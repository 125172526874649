import { AnswerType } from 'components/modals/QuestionList/QuestionList';
import LocationEpisode, { LocationEpisodeOptions } from 'models/LocationEpisode';
import { Note } from 'models/Notes';
import http from 'services/http';

export type DischargePayload = {
  locationEpisodeId: string;
  rehabStateId: string;
  dischargedReason: string;
  dischargedGroupId?: string;
  dischargedGroupTypeId?: string;
  dischargedLocationOther: string | null;
  againstMedicalAdvice: boolean;
  dischargeQuestions: AnswerType[];
  enteredAt: string;
  needs?: string[];
  note?: Note;
};

type AdmitPayload = {
  locationEpisodeId: string;
  enteredAt: string;
  rehabStateId: string;
};

type CreateParams = DischargePayload | AdmitPayload;

export async function createLocationEpisodeRehabState({ locationEpisodeId, ...params }: CreateParams) {
  return http
    .post<LocationEpisodeOptions>(`location_episodes/${locationEpisodeId}/location_episode_rehab_states`, params)
    .then((res) => new LocationEpisode(res.data));
}
