import React, { useMemo, useState } from 'react';

import { MetricConstants, RouteConstants, SourceConstants } from 'components/insights/constants';
import { useInsightsContext } from 'components/insights/InsightsContainer';
import FullGauge from 'components/shared/charts/FullGauge';
import colors from 'styles/theme/colors';

import { DataRow, getRollup } from '../helpers/dataUtils';
import useDataFetch from '../helpers/useDataFetch';

import MetricTile from './MetricTile';

export default function ReadmissionRateTile() {
  const [dischargesCount, setDischargesCount] = useState(-1);
  const [readmissionsCount, setReadmissionsCount] = useState(-1);
  const [readmissionsRate, setReadmissionRate] = useState(-1);

  const { dischargedId } = useInsightsContext();

  const request = useMemo(
    () => ({
      params: {
        source: SourceConstants.LOCATION_EPISODES,
        metrics: [MetricConstants.ID_COUNT, MetricConstants.READMISSIONS_COUNT, MetricConstants.READMISSION_RATE],
      },
      processData: (data: DataRow[]) => {
        const [dischargesCount, readmissionsCount, readmissionsRate] = getRollup(data);

        const rate = dischargesCount && readmissionsRate >= 0 ? readmissionsRate : -1;

        setDischargesCount(dischargesCount);
        setReadmissionsCount(readmissionsCount);
        setReadmissionRate(rate);
      },
    }),
    []
  );

  const { loading, customRef } = useDataFetch([request], {
    condition: dischargedId !== undefined,
    onIntersecting: true,
  });

  const legend = [
    { name: 'Readmissions', value: readmissionsCount, color: '#DC2853' },
    { name: 'Discharges', value: dischargesCount, color: colors.black10 },
  ];

  return (
    <MetricTile
      ref={customRef}
      data-gtm-id='insightsReadmissionRate'
      data-cy='insightsReadmissionRate'
      label='All Cause Readmission Rate'
      showValue={false}
      loading={loading}
      hasData={readmissionsRate !== -1}
      navigateTo={RouteConstants.READMISSION_RATE}>
      <FullGauge
        minValue={0}
        maxValue={dischargesCount}
        value={readmissionsCount}
        colorStops={[[0, '#DC2853']]}
        legend={legend}
        label={`${readmissionsRate.toFixed(1)}%`}
      />
    </MetricTile>
  );
}
