export default function NoteIconOutline({ width = 23, height = 26, ...rest }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none' {...rest}>
      <path
        stroke='#0F1226'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-miterlimit='10'
        d='M13.8 25H1V1h20.8v16l-8 8Z'
      />
      <path
        stroke='#0F1226'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-miterlimit='10'
        d='M13.8 25v-8h8M5.8 7.4H17M5.8 12.2H17M5.8 17H9'
      />
    </svg>
  );
}
