import { ActivityOptions } from 'models/Activity';
import Activity, {
  ActivityType,
  BaseActivityData,
  BaseActivityDataOptions,
  getDefaults as getActivityDefaults,
} from 'models/Activity';
import Group, { GroupOptions } from 'models/Group';
import RehabState, { RehabStateOptions } from 'models/RehabState';

export interface RehabStateChangeOptions extends ActivityOptions {
  type: ActivityType.REHAB_STATE_CHANGE;
  data: Partial<
    BaseActivityDataOptions & {
      currentRehabState: Partial<RehabStateOptions>;
      previousRehabState: Partial<RehabStateOptions>;
      rehabFacility: Partial<GroupOptions>;
      dischargeInfo?: {
        group: Partial<GroupOptions> | null;
        locationOther: Partial<GroupOptions> | null;
        reason: string;
        againstMedicalAdvice: boolean;
      };
    }
  >;
}

export function getDefaults(): RehabStateChangeOptions {
  const defaults = getActivityDefaults();

  return {
    ...defaults,
    type: ActivityType.REHAB_STATE_CHANGE,
    data: {
      ...defaults.data,
      currentRehabState: {},
      previousRehabState: {},
      rehabFacility: {},
    },
  };
}

export default class RehabStateChange extends Activity {
  declare type: ActivityType.REHAB_STATE_CHANGE;
  declare data: {
    currentRehabState: RehabState;
    previousRehabState: RehabState;
    rehabFacility: Group;
    dischargeInfo: {
      group: Group | null;
      locationOther: Group | null;
      reason: string;
      againstMedicalAdvice: boolean;
    };
  } & BaseActivityData;

  constructor(options: Partial<RehabStateChangeOptions> = {}) {
    const opts = { ...getDefaults(), ...options };

    super(opts);

    this.data = {
      ...this.data,
      currentRehabState: new RehabState(opts.data.currentRehabState),
      previousRehabState: new RehabState(opts.data.previousRehabState),
      rehabFacility: new Group(opts.data.rehabFacility),
    };
  }

  get typeLabel() {
    return 'Status Change';
  }
}
