import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useMutation, useQuery } from '@tanstack/react-query';

import CircularProgress from 'components/shared/CircularProgress';
import { ExportContext } from 'models/JobProcess';
import { createEpisodeExport } from 'services/api/episode';
import { showExport } from 'services/api/exports';
import { getJobProcess } from 'services/api/jobProcess';
import { useToastActions } from 'stores/toastStore';
import { colors } from 'styles/theme/colors';
import ExportIcon from 'svg/ExportIcon';

type Props = {
  episodeId: string;
};

export default function EpisodeExportButton({ episodeId }: Props) {
  const { addToast } = useToastActions();
  const [processToMonitor, setProcessToMonitor] = useState<string>();
  const [exportId, setExportId] = useState<string>();
  const [exporting, setExporting] = useState(false);

  const { mutate } = useMutation({
    mutationFn: createEpisodeExport,
    onMutate: () => {
      setExporting(true);
    },
    onSuccess: (res) => {
      setProcessToMonitor(res.id);
    },
  });

  const { data: jobProcess, isError: jobProcessError } = useQuery({
    queryKey: ['jobProcess', processToMonitor],
    queryFn: () => getJobProcess(processToMonitor ?? ''),
    enabled: !!processToMonitor,
    refetchInterval: 1500,
  });

  const { data: exportInfo, isError: exportError } = useQuery({
    queryKey: ['episodeExport', exportId],
    queryFn: () => showExport(exportId ?? ''),
    enabled: !!exportId,
  });

  useEffect(() => {
    if (jobProcess?.isFinished) {
      setProcessToMonitor(undefined);
      setExportId((jobProcess.context as ExportContext).exportId);
    }
  }, [jobProcess, episodeId]);

  useEffect(() => {
    if (exportInfo?.url) {
      setExporting(false);
      window.open(exportInfo.url, '_blank');
    }
  }, [exportInfo]);

  useEffect(() => {
    if (exportError || jobProcessError || jobProcess?.error) {
      addToast({ text: 'Something went wrong with the export. Please try again.' });
      setExporting(false);
      setProcessToMonitor(undefined);
    }
  }, [addToast, exportError, jobProcess, jobProcessError]);

  return (
    <>
      {exporting ? (
        <ExportLoadingIndicator color={colors.black} thickness={2} />
      ) : (
        <ActionIcon onClick={() => mutate(episodeId)}>
          <ExportIcon color={colors.black} />
        </ActionIcon>
      )}
    </>
  );
}

const ActionIcon = styled.div`
  display: flex;
  cursor: pointer;
`;

const ExportLoadingIndicator = styled(CircularProgress)`
  width: 20px;
  height: 20px;
`;
