import styled from 'styled-components';

import { ColumnDef } from '@tanstack/react-table';

import { AlosTableRow } from 'components/insights/layout/details/AverageLengthOfStayDetail';
import { ActivitiesPerPatientPerWeekTableRow } from 'components/insights/layout/details/helpers/activitiesPerPatientPerWeekUtils';
import { GroupResponseTimeRow, GroupRow } from 'components/insights/layout/details/helpers/utils';
import { OesTableRow } from 'components/insights/layout/details/OlioEngagementScoreDetail';
import { ReadmissionRateTableRow } from 'components/insights/layout/details/readmissionRateDetail/ReadmissionRateDataTable';
import useIsMobile from 'hooks/useIsMobile';
import ExportInsights from 'svg/ExportInsights';

type exportColumns =
  | ColumnDef<GroupRow, any>[]
  | ColumnDef<AlosTableRow, any>[]
  | ColumnDef<OesTableRow, any>[]
  | ColumnDef<ReadmissionRateTableRow, any>[]
  | ColumnDef<ActivitiesPerPatientPerWeekTableRow, any>[]
  | ColumnDef<GroupResponseTimeRow, any>[];

type Props = {
  columns: exportColumns;
  data: GroupRow[];
  loading: boolean;
  filePrefix: string;
};

function generateCSV(columns: exportColumns, data: GroupRow[]) {
  let csv = '';
  columns.forEach((column, index) => {
    csv += column.header + (index === columns.length - 1 ? '\n' : ',');
  });
  data.forEach((row) => {
    columns.forEach((column, index) => {
      csv += row[column.accessorKey] + (index === columns.length - 1 ? '\n' : ',');
    });
  });
  return csv;
}

function downloadCSV(csv: string, filePrefix: string) {
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${filePrefix}-export-${new Date().toISOString().split('T')[0]}.csv`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

function Export({ columns, data, loading, filePrefix }: Props) {
  const isMobile = useIsMobile();
  return (
    !loading &&
    !isMobile && (
      <Container>
        <ExportLink
          hasData={data.length > 0}
          onClick={() => {
            if (data.length > 0) {
              downloadCSV(generateCSV(columns, data), filePrefix);
            }
          }}>
          <ExportInsights />
          <ExportText>Export</ExportText>
        </ExportLink>
      </Container>
    )
  );
}

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const ExportText = styled.div`
  padding-left: 12px;
`;
const ExportLink = styled.a<{ hasData: boolean }>`
  display: flex;
  padding-left: 6px;
  cursor: ${(props) => (props.hasData ? 'pointer' : 'not-allowed')};
  font-size: 14px;
  font-weight: normal;
  opacity: ${(props) => (props.hasData ? 1 : 0.25)};
  color: ${({ theme }) => theme.colors.primaryBlue};
`;

export default Export;
