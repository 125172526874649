import styled from 'styled-components';

import { getLocationTypeForOwningClient, getOwnerText } from 'components/intake/EpisodeInfoSection';
import { ownerLocationTypesToRequireHospitalField } from 'components/intake/intakeFormValidation';
import { FormMeta, FormValues, PatientFormMode } from 'components/intake/types';
import FormHeader from 'components/shared/form/FormHeader';
import { Flags } from 'constants/flags';
import { useForm } from 'context/form';
import { useProfile } from 'context/profile';
import { simpleDate } from 'lib/date';
import { colors } from 'styles/theme/colors';

import Button from '../shared/Button';
import { Actions } from '../shared/StandardModal';

export type PatientConfirmType = {
  onPreviousStep: () => void;
};

export default function ReviewPatientDetails({ onPreviousStep }: PatientConfirmType) {
  const { profile } = useProfile();
  const clientType = profile?.clientType;

  const { values, meta } = useForm<FormValues, FormMeta>();

  const connecting = meta.mode === PatientFormMode.Connecting;
  const locationType = values.rehabFacility?.groupType?.displayName || '';

  const ownerLabel = getOwnerText(clientType);

  const items = [
    { label: 'Date of Birth', value: values.dateOfBirth ? simpleDate(values.dateOfBirth) : <>&mdash;</> },
    { label: 'Gender', value: values.sex?.label },
    { label: 'Hospital Admission Date', value: simpleDate(values.admittedOn) },
    { label: ownerLabel, value: values.owner?.name },
    {
      label: 'Hospital',
      value: values.hospital?.name,
      renderCondition: ownerLocationTypesToRequireHospitalField.includes(getLocationTypeForOwningClient(clientType)),
    },
    {
      label: 'Utilization Manager',
      value: values.utilizationManager?.fullName || <>&mdash;</>,
      renderCondition: profile?.hasFlag(Flags.UtilizationManagerAssignment),
    },
    {
      label: 'Case Manager',
      value: values.caseManager?.fullName || <>&mdash;</>,
      renderCondition: profile?.hasFlag(Flags.CaseManagerAssignment),
    },
    { label: 'Physician', value: values.physicianTeam?.name },
    { label: `${locationType} Location`, value: values.rehabFacility?.name },
    { label: 'Plan Type', value: values.planTypeClassification?.name || <>&mdash;</> },
    { label: 'Episode Type', value: values.episodeClassification?.name || <>&mdash;</> },
    {
      label: 'Anticipated Hospital Discharge',
      value: simpleDate(values.antHospitalDischarge),
      renderCondition: connecting,
    },
    {
      label: 'Patient Needs',
      value: values.needs?.length ? (
        values.needs?.sort().map((need, index) => (
          <div key={index} style={{ marginBottom: '8px' }}>
            {need}
          </div>
        ))
      ) : (
        <>&mdash;</>
      ),
      renderCondition: connecting,
    },
    { label: 'Note', value: values.note?.text, renderCondition: !!values.note?.text },
  ];

  if (values.note?.attachments.length) {
    values.note.attachments.forEach((attachment) => {
      items.push({
        label: 'Attachment',
        value: <div style={{ marginBottom: '8px' }}>{attachment.filename}</div>,
      });
    });
  }

  return (
    <>
      <FormHeader title='Review and Confirm Details' subtitle={`Patient: ${values.name}`} />
      {items.map((item, index) => {
        if (typeof item.renderCondition != 'undefined' && !item.renderCondition) return null;
        if (!item.value) return null;

        return (
          <ConfirmationContainer key={index}>
            <ConfirmationLabel>{item.label}</ConfirmationLabel>
            <ConfirmationValue>{item.value}</ConfirmationValue>
          </ConfirmationContainer>
        );
      })}
      <ActionContainer>
        <Actions>
          <Button variant='ghost' onClick={onPreviousStep} disabled={meta.isSubmitting} data-cy='clickable'>
            Back
          </Button>
          <Button disabled={meta.isSubmitting} loading={meta.isSubmitting} data-cy='clickable' type='submit'>
            Confirm and Save
          </Button>
        </Actions>
      </ActionContainer>
    </>
  );
}

const ConfirmationContainer = styled.div`
  padding: 16px 0;
  width: 100%;
  display: flex;
  border-bottom: 1px solid ${colors.black25};
  word-break: break-word;
`;

const ConfirmationLabel = styled.div`
  color: ${colors.black50};
  font-size: 14px;
  font-weight: 700;
  flex-basis: 50%;
  line-height: 16px;
`;

const ConfirmationValue = styled.div`
  color: black;
  font-size: 14px;
  font-weight: 400;
  flex-basis: 50%;
  line-height: 16px;
`;

const ActionContainer = styled.div`
  padding-top: 35px;
`;
