import { useMemo, useState } from 'react';
import styled from 'styled-components';

import DischargeConfirm from 'components/dischargeForm/DischargeConfirm';
import DischargeFileRequests from 'components/dischargeForm/DischargeFileRequests';
import DischargeInformation, {
  DischargeFormMeta,
  DischargeFormValues,
} from 'components/dischargeForm/DischargeInformation';
import DischargeNeeds from 'components/dischargeForm/DischargeNeeds';
import DischargeQuestions from 'components/dischargeForm/DischargeQuestions';
import { buildDischargeFormSteps, DischargeSteps } from 'components/dischargeForm/helpers';
import Button from 'components/shared/Button';
import ButtonGroup from 'components/shared/ButtonGroup';
import { PatientName } from 'components/shared/StandardModal';
import { useForm } from 'context/form';
import { DocumentTypes } from 'models/Attachment';
import LocationEpisode from 'models/LocationEpisode';

type Props = {
  patientName: string;
  locationEpisode: LocationEpisode;
  onCancel: () => void;
  dischargeQuestionsFlagEnabled: boolean;
  showActualDischargeDate?: boolean;
};

const REQUESTED_DOCS = [DocumentTypes.DischargeSummaryPostAcute];

export default function DischargeForm({
  locationEpisode,
  patientName,
  onCancel,
  dischargeQuestionsFlagEnabled,
  showActualDischargeDate = false,
}: Props) {
  const [step, setStep] = useState(DischargeSteps.Information);

  const { valid, dirty, values, submitForm, meta } = useForm<DischargeFormValues, DischargeFormMeta>();

  const questions = useMemo(
    () => (dischargeQuestionsFlagEnabled ? (locationEpisode.dischargeTemplate?.questions ?? []) : []),
    [dischargeQuestionsFlagEnabled, locationEpisode.dischargeTemplate?.questions]
  );

  const steps = useMemo(
    () =>
      buildDischargeFormSteps({
        hasDischargeQuestions: !!questions.length,
        canContinueCare: !!values.dischargedReason?.canContinueCare && values.dischargedGroup?.name !== 'Other',
        hasAllRequestedFiles: REQUESTED_DOCS.every((doc) =>
          (values.note?.attachments || []).some((a) => a.docType === doc)
        ),
        currentStep: step,
      }),
    [step, questions.length, values.dischargedGroup, values.dischargedReason?.canContinueCare, values.note?.attachments]
  );

  const hasMoreSteps = useMemo(() => steps.indexOf(step) < steps.length - 1, [step, steps]);

  const onPreviousStep = () => {
    if (step === DischargeSteps.Information) {
      onCancel();
      return;
    }

    const currentIndex = steps.indexOf(step);
    setStep(steps[currentIndex - 1]);
  };

  const onNextStep = () => {
    if (!hasMoreSteps) {
      submitForm();
      return;
    }

    const currentIndex = steps.indexOf(step);
    setStep(steps[currentIndex + 1]);
  };

  const allQuestionsAnswered = useMemo(() => {
    return questions.every((q) => values.questions?.[q.id]);
  }, [questions, values.questions]);

  return (
    <>
      <PatientName>Patient: {patientName}</PatientName>

      {step === DischargeSteps.Information && (
        <DischargeInformation locationEpisode={locationEpisode} showActualDischargeDate={showActualDischargeDate} />
      )}
      {step === DischargeSteps.FileRequests && <DischargeFileRequests />}
      {step === DischargeSteps.Questions && <DischargeQuestions questions={questions} />}
      {step === DischargeSteps.Needs && <DischargeNeeds />}
      {step === DischargeSteps.Confirm && <DischargeConfirm />}

      <Actions>
        <Button variant='ghost' type='button' onClick={onPreviousStep}>
          {step === DischargeSteps.Information ? 'Cancel' : 'Back'}
        </Button>
        <Button
          type='button'
          disabled={
            !valid ||
            !dirty ||
            meta.isUploadingAttachments ||
            (step === DischargeSteps.Questions && !allQuestionsAnswered)
          }
          loading={meta.isSubmitting}
          onClick={onNextStep}>
          {hasMoreSteps ? 'Next' : 'Confirm'}
        </Button>
      </Actions>
    </>
  );
}

const Actions = styled(ButtonGroup)`
  margin-top: 24px;
  justify-content: flex-end;
`;
