import { camelCase } from 'lodash';

export enum GroupTypeApiName {
  PROVIDER = 'provider',
}

type Type = {
  apiName: GroupTypeApiName;
  displayName: string;
};

export interface GroupTypeOptions {
  id: string;
  apiName: string;
  displayName: string;
  name: string;
  isInpatient: boolean;
  dischargeReason: string | null;
  dischargeReasonVisibleGlobally: boolean;
  config: Record<string, unknown>;
  createdAt: string | null;
  updatedAt: string | null;
  type: Type;
}

function getDefaults(): GroupTypeOptions {
  return {
    id: '',
    apiName: '',
    displayName: '',
    name: '',
    isInpatient: false,
    dischargeReason: '',
    dischargeReasonVisibleGlobally: false,
    config: {},
    createdAt: null,
    updatedAt: null,
    type: {
      apiName: GroupTypeApiName.PROVIDER,
      displayName: '',
    },
  };
}
/**
 * @class GroupType
 * @classdesc Represents a group type in the system
 * @property {string} id - The group type's id
 * @property {string} apiName - The group type's api name
 * @property {string} displayName - The group type's display name
 * @property {string} name - The group type's name
 * @property {boolean} isInpatient - Whether the group type is inpatient
 * @property {string | null} dischargeReason - The group type's discharge reason
 * @property {boolean} dischargeReasonVisibleGlobally - Whether the group type's discharge reason is visible globally
 * @property {Record<string, unknown>} config - The group type's config
 * @property {string | null} createdAt - The group type's creation date
 * @property {string | null} updatedAt - The group type's update date
 * @property {Type} type - The group type's type
 * @param {Partial<GroupTypeOptions>} [options={}]
 * @example const groupType = new GroupType({ id: '123' });
 */
export default class GroupType {
  id: string;
  apiName: string;
  displayName: string;
  name: string;
  isInpatient: boolean;
  dischargeReason: string | null;
  dischargeReasonVisibleGlobally: boolean;
  config: Record<string, unknown>;
  createdAt: string | null;
  updatedAt: string | null;
  type: Type;

  constructor(options: Partial<GroupTypeOptions> = {}) {
    const opts = { ...getDefaults(), ...options };
    this.id = opts.id;
    this.apiName = opts.apiName;
    this.displayName = opts.displayName;
    this.name = opts.name;
    this.isInpatient = opts.isInpatient;
    this.dischargeReason = opts.dischargeReason;
    this.dischargeReasonVisibleGlobally = opts.dischargeReasonVisibleGlobally;
    this.config = opts.config;
    this.createdAt = opts.createdAt;
    this.updatedAt = opts.updatedAt;
    this.type = opts.type as Type;
  }

  get isProvider() {
    return this.type.apiName === GroupTypeApiName.PROVIDER;
  }

  get camelCaseApiName() {
    return camelCase(this.apiName);
  }
}
