import { addDays } from 'date-fns/addDays';
import { useNavigate, useParams } from 'react-router-dom';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import Intake from 'components/intake/Intake';
import { FormMeta, FormValues, PatientFormMode } from 'components/intake/types';
import { FormHelpers } from 'context/form/types';
import { useProfile } from 'context/profile';
import ImportedPatient from 'models/ImportedPatient';
import { showImportedPatient } from 'services/api/importedPatients';
import { createPatient } from 'services/api/patient';
import { portfolioQueryKeys } from 'services/api/portfolio';
import { useToastActions } from 'stores/toastStore';

export default function ConnectPatientPage() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { addToast } = useToastActions();
  const { id } = useParams<{ id?: string }>();
  const { profile } = useProfile();

  const { data: importedPatient } = useQuery({
    queryKey: ['importedPatient', id],
    queryFn: () =>
      showImportedPatient({
        id: id ?? '',
        include: 'owner,hospital,payer,physician_group,physician_team,episode_classification,plan_type_classification',
      }),
    enabled: !!id,
  });

  const { mutate: createNewPatient } = useMutation({
    mutationFn: createPatient,
  });

  const handleSubmit = async (values: Partial<FormValues>, _meta, helpers: FormHelpers<FormValues, FormMeta>) => {
    helpers.setMeta('isSubmitting', true);

    const patientValues = ImportedPatient.fromFormValues(values).serialize();

    createNewPatient(patientValues, {
      onSuccess: (patient) => {
        queryClient.invalidateQueries({ queryKey: portfolioQueryKeys.lane('queue') });
        addToast({ text: 'Patient connected successfully.' });
        navigate(`/patients/${patient.episodeId}`);
      },
      onError: () => {
        addToast({ text: 'Something went wrong. Please try again.' });
      },
      onSettled: () => {
        helpers.setMeta('isSubmitting', false);
      },
    });
  };

  if (!importedPatient) return <></>;

  const initialValues = importedPatient?.intakeFormValues();

  if (!profile.actingClient?.isPayor) {
    initialValues.antHospitalDischarge = addDays(new Date(), 1).toISOString();
  }

  return (
    <Intake
      connecting
      initialMeta={{ isSubmitting: false, mode: PatientFormMode.Connecting }}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      patient={importedPatient}
    />
  );
}
