import { useEffect } from 'react';
import styled from 'styled-components';

import ActivityInput from 'components/shared/activityInput/ActivityInput';
import useActivityInput, { ActivityInputValues } from 'components/shared/activityInput/useActivityInput';
import Button from 'components/shared/Button';
import ButtonGroup from 'components/shared/ButtonGroup';
import FieldLabel from 'components/shared/form/FieldLabel';
import { Select } from 'components/shared/form/InfiniteScrollDropdown';
import InputGroup from 'components/shared/form/InputGroup';
import { LONG_TERM_CARE } from 'constants/locationTypes';
import { Form, useForm } from 'context/form';
import AttrValue from 'models/AttrValue';
import Group from 'models/Group';
import LocationEpisode from 'models/LocationEpisode';
import AltcsApplication from 'models/reviews/AltcsApplication';
import { useInfiniteGroups } from 'services/api/group';

export type ApprovalFormValues = {
  ltcGroup: Group;
  provider: AttrValue;
  note: ActivityInputValues;
};

export type ApprovalFormMeta = {
  isSubmitting: boolean;
  isUploadingAttachments: boolean;
};

type Props = {
  altcsApplication: AltcsApplication;
  locationEpisode: LocationEpisode;
  onCancel: () => void;
};

export default function AltcsApprovalForm({ altcsApplication, locationEpisode, onCancel }: Props) {
  const { valid, dirty, meta, setMeta, setValue, values } = useForm<ApprovalFormValues, ApprovalFormMeta>();

  const { note, setNote } = useActivityInput({ ...values.note });

  const asyncGroupOptions = useInfiniteGroups({
    ownerId: locationEpisode.owner.id,
    type: LONG_TERM_CARE,
    sortBy: 'name asc',
    viewOnly: true,
  });

  useEffect(() => {
    setValue('note', note);
  }, [note, setValue]);

  return (
    <Form>
      <InputGroup title='ALTCS Provider'>
        <Select<AttrValue>
          placeholder='Select an ALTCS Provider'
          options={altcsApplication.attrValues.sort((a, b) => a.name.localeCompare(b.name))}
          onChange={(change) => setValue('provider', change)}
          getOptionValue={(prop) => prop.id}
          getOptionLabel={(prop) => prop.name}
          value={values.provider}
        />
      </InputGroup>

      <InputGroup title='LTC'>
        <Select<Group>
          {...asyncGroupOptions}
          placeholder='Select a location'
          onChange={(change) => setValue('ltcGroup', change)}
          getOptionValue={(prop) => prop.id}
          getOptionLabel={(prop) => prop.name}
          value={values.ltcGroup}
        />
      </InputGroup>
      <FieldLabel>Notes (optional)</FieldLabel>
      <ActivityInput
        showPostButton={false}
        showEscalate={false}
        locationEpisodeId={locationEpisode.id}
        values={note}
        setValues={setNote}
        onUploading={(uploading) => setMeta('isUploadingAttachments', uploading)}
      />
      <Actions>
        <Button variant='ghost' type='button' onClick={onCancel}>
          Cancel
        </Button>
        <Button type='submit' disabled={!valid || !dirty || meta.isUploadingAttachments} loading={meta.isSubmitting}>
          Confirm
        </Button>
      </Actions>
    </Form>
  );
}

const Actions = styled(ButtonGroup)`
  margin-top: 24px;
  justify-content: flex-end;
`;
