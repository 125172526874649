import {
  ADDITIONAL_FILTERS,
  COMANAGEMENT_TEAM,
  createProviderDropdown,
  EPISODE_TYPE_DROPDOWN,
  getComanagementTeamAcuteFilters,
  LATEST_REHAB_STATE_DROPDOWN,
  PLAN_TYPE_DROPDOWN,
} from 'constants/filterConfigs';
import { ClientType } from 'models/Client';
import GroupType from 'models/GroupType';
import { useSearchableRehabStates } from 'services/api/rehabStates';

const useSearchableRehabStatesWithoutQueue = () => useSearchableRehabStates({ apiNamesToExclude: ['queue'] });

const insightsRehabStateDropdown = {
  ...LATEST_REHAB_STATE_DROPDOWN,
  name: 'rehabState',
  getOptions: {
    hook: useSearchableRehabStatesWithoutQueue,
  },
};

export const renderableFilters = (selectedGroupType: GroupType, actingClientType: ClientType) => {
  return [
    {
      title: COMANAGEMENT_TEAM,
      filters: [createProviderDropdown(selectedGroupType), ...getComanagementTeamAcuteFilters(actingClientType)],
    },
    {
      title: ADDITIONAL_FILTERS,
      filters: [PLAN_TYPE_DROPDOWN, EPISODE_TYPE_DROPDOWN, insightsRehabStateDropdown],
    },
  ];
};
