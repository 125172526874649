export default function PatientsIcon({ width = 26, height = 24, ...rest }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none' {...rest}>
      <path
        stroke='#0F1226'
        stroke-linecap='square'
        stroke-miterlimit='10'
        d='m15.84 18.27-3.94-1.127a.8.8 0 0 1-.574-.656l-.332-2.324s2.981.225 4.406-1.162a8.183 8.183 0 0 1-1.6-5.39A4.948 4.948 0 0 0 9.19 2.604 4.8 4.8 0 0 0 4.2 7.4 8.361 8.361 0 0 1 2.6 13c1.425 1.387 4.406 1.162 4.406 1.162l-.332 2.324a.8.8 0 0 1-.573.656L2.16 18.27A1.6 1.6 0 0 0 1 19.808V22.6h16v-2.793a1.601 1.601 0 0 0-1.16-1.538ZM20.2 22.6H25v-4.393a1.6 1.6 0 0 0-1.161-1.538l-3.94-1.127a.8.8 0 0 1-.573-.656l-.332-2.324s2.98.225 4.406-1.162a8.183 8.183 0 0 1-1.6-5.39 4.948 4.948 0 0 0-4.609-5.006 4.778 4.778 0 0 0-2.845.8'
      />
    </svg>
  );
}
