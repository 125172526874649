import { useMemo } from 'react';
import { camelCase } from 'lodash';

import { DefaultError, InfiniteData, useInfiniteQuery, UseInfiniteQueryOptions } from '@tanstack/react-query';

import { DATES, END_DATE, START_DATE } from 'constants/filterKeysConstants';
import { Analytics, retrieveAnalytics } from 'services/api/insights/analytics';
import { useInsightsStore } from 'stores/insightsStore';

import { DimensionConstants, MetricConstants, SourceConstants } from '../../constants';

type QueryResult = Awaited<ReturnType<typeof retrieveAnalytics>>;

export default function useAnalyticsInfiniteQuery(
  queryParams: {
    source: SourceConstants;
    dimensions: DimensionConstants[];
    metrics: MetricConstants[];
    sortBy: string;
    pageSize: number;
  },
  options: Omit<
    UseInfiniteQueryOptions<QueryResult, DefaultError, InfiniteData<Analytics, Analytics>>,
    'queryKey' | 'queryFn'
  >
) {
  const filters = useInsightsStore((state) => state.filters);
  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);

  const analyticsFilters = useMemo(() => {
    if (!selectedGroupType) return null;

    const rehabFacilitiesFilterKey = camelCase(selectedGroupType.apiName);

    const { [START_DATE]: start, [END_DATE]: end, [rehabFacilitiesFilterKey]: group, ...rest } = filters;

    return { ...rest, [DATES]: [{ start, end }], groupType: selectedGroupType.id, group };
  }, [filters, selectedGroupType]);

  return useInfiniteQuery({
    queryKey: ['analytics', queryParams, analyticsFilters],
    queryFn: async ({ pageParam }) => retrieveAnalytics(pageParam, analyticsFilters),
    ...options,
  });
}
